<template>
  <div>
    <div class="flix-form-group">
      <a href="#" @click.prevent="qr = true">
        <vue-qrcode :width="100" :value="url + link" />
      </a>
      <br />
      <a :href="url + link" target="_blank" class="flix-url flix-html-small flix-html-a flix-text-danger">{{ url }}{{ link }}</a>
    </div>
    <div v-if="qr" class="flix-fullscreen flix-center">
      <div><flixIcon id="remove" /> <a href="#" class="flix-html-a" @click.prevent="qr = false">{{ $t('message.close', {name: ''}) }}</a></div>
      <vue-qrcode ref="qrCodeDownload" :width="350" :value="url + link" />
      <div class="flix-form-group">
        <a :href="url + link" target="_blank" class="flix-url flix-html-small flix-html-a flix-text-danger">{{ url }}{{ link }}</a>
      </div>
      <a href="#" class="flix-html-a" @click.prevent="downloadQR"><flixIcon id="download" /> {{ $t('message.save', {name: $t('message.image')}) }}</a>
    </div>
  </div>
</template>
<script>
import VueQrcode from 'vue-qrcode'
export default {
  components: {
    VueQrcode
  },
  props: {
    url: String,
    link: {
      type: [String, Boolean],
      default () { return 'index.html' }
    }
  },
  data () {
    return {
      qr: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    downloadQR () {
      var link = document.createElement('a')
      link.download = 'Bookingflix_QR_' + new Date().getTime()
      link.href = this.$refs.qrCodeDownload.$el.src
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

  }
}
</script>
<style lang="sass" scoped>
  .flix-center
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
  .flix-html-a
    display: inline-block
    max-width: 100%
    word-wrap: break-word
  .flix-url
    word-wrap: break-word
</style>
